import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const Page = () => (
  <Layout>
    <SEO title="Self-Exercise Timer" />
    <h1>Self Exercise Timer</h1>
    <label>
      Active time:
      <input type="text" />
      <br />
    </label>
    <label>
      Rest time:
      <input type="text" />
      <br />
    </label>
    <label>
      Number of sets:
      <input type="text" />
      <br />
    </label>
    <CountdownDisplay>00:00.00</CountdownDisplay>
    <input type="submit" value="Start" /> {" "}
    <input type="submit" value="Pause" /> {" "}
    <input type="submit" value="Stop" /> <br />
    You have been exercising for{}
    <br />
    <br />
    <br />
  </Layout>
);
const CountdownDisplay = styled.div`
font-size: 70px;
line-height: normal;
`

export default Page;
